/* General styles for the landing page */
body{
  overflow:-moz-hidden-unscrollable;
}
.landing-page {
    position: relative;
    height: 100vh;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;

  }

  
  .landing-page::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("./assets/images/Header.png"); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    transform: rotate(180deg);
    z-index: -1; /* Send it behind the content */
  }
  
  /* Social Media Icons */
  .social-icons-bottom{
    display: flex;
    gap: 1.5rem;
    margin-bottom: 2rem;
    text-align:center;
    color:white;
    width:100%;
  }
  .social-icons-bottom a {
    text-decoration: none;
    color: white !important;
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  .social-icons-bottom a:hover{
    transform:scale(1.2);
  } 
  .spotify:hover{
    color:#1DB954 !important;
  }
  .youtube:hover{
    color:red !important;
  }
  .soundcloud:hover{
    color:#FF5500 !important;
  }
  .facebook:hover{
    color:#3b5998 !important;
  }
.instagram:hover{
    color:#C13584 !important;
  }
  .email:hover{
    color:black !important;
  }
  .social-link {
    color: white;
    font-size: 2rem;
    transition: color 0.3s ease;
  }
  
  .social-link:hover {
    color: #00ff00; /* Green hover effect */
  }
  .signup{
    color:white;
    font-size:1.5rem;
    background-color:rgba(0,0,0,0.5);
    border-radius:40px;
    padding:10px;
  }
  /* Newsletter Signup */
  .newsletter-signup {
    margin-bottom: 2rem;
  }
  
  .newsletter-text {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .newsletter-input {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid white;
    border-radius: 5px;
    background-color: transparent;
    color:black;
    width:500px;
    height:50px;
    text-align: center;
    background-color:white;
  }
  .newsletter-input::placeholder {
    color: black;
  }
  .newsletter-button {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid white;
    border-radius: 5px;
    background-color: transparent;
    color:black;
    width: 300px;
    text-align: center;
    background-color:violet;
  }
  .receipt{
    font-size: 2rem;
    margin-bottom: 1rem;
    color: white;
    background-color:rgba(0,0,0,0.5);
    border-radius:40px;
  }
  

  
  /* Footer */
  .footer {
    font-size: 1.5rem;
    font-family: curs;
    background-color: transparent;
  }
