
.merchandize{
    width:100vw;
    display:flex;
    flex-wrap:wrap;
    justify-content:space-around;
    align-items:center;
    padding-bottom:30px;
}
.merchandize h1{
    display:absolute;
}
.merchTitle{
    width:100vw;
    text-align:center;
    color:black !important;
    margin:100px 0px;
    z-index:2;
    background-color:rgba(244,244,244,0.8);
}
.moveBox{
    background-color:black;
    width:0vw;
    height:10px;
    animation:grow 10s infinite alternate;
}
@keyframes grow{
    0%{
        width:0vw;
    }
    100%{
        width:100vw;
    }
}
.moveBoxop{
    background-color:black;
    width:100vw;
    height:10px;
    animation:growop 10s infinite alternate;
}
@keyframes growop{
    0%{
        width:100vw;
    }
    100%{
        width:0vw;
    }
}

.merchTitle h2{
    color:black !important;
    font-size:1.3em;

}
.merchTitle p{
    color:black !important;
    font-size:1.3em;

}


.merchItem{
    width:40vw;
    height:auto;
    border:1px solid black;
    border-radius:20px;
    padding:15px;
    transition:all 1s;
    background-color:rgba(244,244,244,1);
}
