/* App.css */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  
}

.background {
  position: fixed;
  background-repeat: no-repeat;
  z-index:-10;
}

@keyframes Blur{
  0%{
    opacity:0.7;
    top:-25%;
  }
  50%{
    opacity:0.3;
    top:-24.5%;
  }
  100%{
    opacity:0.7;
    top:-25%;
  }
}
.background-content{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-image: url('./assets/images/Fontego_HvitHorizontal.png'); /* Replace with your background image path */
  opacity:0.05;
  z-index: -5;
  animation:360s fadeIn infinite linear;
}
@keyframes fadeIn{
  0%{
    transform:rotate(0deg);
    opacity:0.08;
  }
  100%{
    transform:rotate(359deg);
    opacity:0.1;
  }
}

.content {
  position: relative;
  z-index: 10; /* Make sure content stays above the background */
  height: 200vh; /* Make the page scrollable */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: white;
  font-family: Arial, sans-serif;
}
