
.mfront-page {
    display: flex;
    width:100vw;
    justify-content: space-between;
    background-color:black;
    margin-top:125px;
    
  }
  .mfloattext{
      position: absolute; /* Makes the text "stick" within the container */
      font-family: "Telugu MN", serif;
      font-size: 3rem;
      font-weight: bold;
      z-index: 10; /* Ensure it stays above the image */
      width:20%;
      height:auto;
      display: flex;
      justify-content: flex-end; /* Aligns the nav to the right */
  
  }
.mbanddescription{
  color:white;
  text-align: center;
  margin-top:-50px;
}
  
  #mfront-logo{
    position:fixed;
    bottom:150px;
    right:150px;
  }
  .mlogo {
    width: 300px; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
  }
  .mlogo {
    height: 50px;
  }
  
  .mnav-list {
    display: flex;
    list-style: none;
  }
  
  .mnav-list li {
    margin: 0 10px;
  }
  
  .mnav-list a {
    color: white;
    text-decoration: none;
  }







.musicContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
#mmusic-container{
text-align:center;

}
.music{
margin:auto;
flex:1 1 50%;
}
.music0{
order:1;

}
.music1{
order:2;

}
.music2{
order:3;

}
.music3{
order:4;

}
.music4{
order:5;

}
.music5{
order:6;


}
.music6{
order:7;


}
.music7{
order:8;


}
.music8{
order:9;


}
.music9{
order:10;


}
.title {
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin: 0;
}

.image-wrapper {
  max-width: 300px;
  padding: 10px;
  background-color:rgba(244,244,244,0.5);
  border-radius:10px;
  transition:background-color 0.3s;
  animation:bgColor 5s infinite;
  
}
@keyframes bgColor {
  0% {
    background-color:rgba(244,244,244,0.5);
  }
  50% {
    background-color:rgba(244,244,244,0.7);
  }
  100% {
    background-color:rgba(244,244,244,0.5);
  }
}


.image-wrapper img {
  width: 100%;
  height: auto;
  display: block;
}

.subtitle-wrapper {
  position: relative;
  display: inline-block; /* Ensure the wrapper only takes up as much space as the subtitle */
  width:350px;
  margin:10px;
}

.subtitle {
  width:80%;
  margin: auto;
  color: black;
  font-size:1.3em;
  text-shadow: 0px 0px 10px rgba(244, 244, 244, 1); /* Black shadow for better visibility */
  position: relative; /* Ensure the subtitle is positioned relative to the wrapper */
  z-index: 1; /* Ensure the subtitle is above the backborder */
}



.headline-wrapper {
  position: relative;
  display: inline-block; /* Ensure the wrapper only takes up as much space as the subtitle */
}

.headtitle {
  font-size: 1.5rem;
  text-transform: uppercase;
  margin: 0;
  color: black;
  font-size: 3em;
  text-shadow: 0px 0px 10px rgba(244, 244, 244, 1); /* Black shadow for better visibility */
  position: relative; /* Ensure the subtitle is positioned relative to the wrapper */
  z-index: 1; /* Ensure the subtitle is above the backborder */
}

.backborderhd {
  position: absolute;
  height:75px;
  width:350px;
  background-color:rgba(244,244,244,0.3);
  border-radius:0%;
  box-shadow: 0 0 40px rgba(244, 244, 244, 0.6);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(0deg); /* Center the.subtitle-wrapper {
  position: relative;
  display: inline-block; /* Ensure the wrapper only takes up as much space as the subtitle */
}
.mlinkTitle {
  margin: 0;
  width:100vw;
  color:white !important;
  font-size:1.5em;
  color:rgba(0,0,0,0.8);
  text-shadow: 0px 0px 10px rgba(244, 244, 244, 0.8); /* Black shadow for better visibility */
  position: relative; /* Ensure the subtitle is positioned relative to the wrapper */
  z-index: 1; /* Ensure the subtitle is above the backborder */
}
.mlinkTitle h1{
  font-size:1.5em;
  margin:0 ;
}
.mlinksContainer{
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  gap:5px;
}
.mlinks-wrapper{
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  width:60%;
  background-color:rgba(244,244,244,1);
  border-radius:10px;
  margin-top:10px;
}
.linkTitle h1{
  font-size:1em;
  margin:0 0 10px 0;
}
.linkTitle small{
  font-size:0.7em;
  margin:20px;
}

.social-icons {
  display: flex;
  justify-content: center;
}

.social-icons a {
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
  transition: color 0.3s ease;
  transform:scale(1.5);
  margin:20px;
  text-shadow: 2px 2px 4px rgba(0, 244, 0, 1); /* Black shadow for better visibility */
}

.mpromoDescription{
  margin:10px;
  padding:30px 0px;
  border-radius:10px;
  text-align:center;
  font-size:1em;
}
#music-container {
  perspective: 1000px;
}


/* Shows.css */
.shows-container {
  background-image: url("./assets/images/Void.png");
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  }
  .shows-container div{
    flex: 1 1 33%;
  }
  .shows-container section{
    width:800px;
  }
  .calObj {
    margin-bottom: 20px;
    border:1px dotted black;
    border-radius:20px;
    padding:50px;
    transition:all 1s;
    background-color:rgba(244,244,244,1);
  
  }
  .calObj:hover {
    transform:scale(1.1);
    background-color:rgba(244,244,244,1);
  
  }
  
  
  .calObj strong {
    display: block;
    font-size: 1.2em;
  }
  
  .calObj p {
    margin: 5px 0;
  }
  
  .sidebar {
    width: 200px;
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  .city-list {
    list-style: none;
    padding: 0;
  }
  
  .city-list li {
    cursor: pointer;
    padding: 5px 0;
  }
  
  .city-list li.active {
    font-weight: bold;
    text-decoration: underline;
  }
  
  .filter-section {
    margin-left: 20px;
  }
  
  .filter-section select {
    padding: 5px;
    font-size: 1rem;
  }
  
  /* Remove dots from all lists */
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  /* Past Events List */
  .past-events-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .past-events-list .calObj {
    flex: 1 1 calc(50% - 20px); /* Two items per row with a gap of 20px */
    box-sizing: border-box;
  }
  /* Banner styles */
  .mbanner {
    display: none;
    width: 50%;
    height: 200vh;
    background-image: url("./assets/images/Fontego_HvitHorizontal.png");
    background-position: center;
    background-size: 110%;
    background-position-y: 75px;
    margin: 0 20px;
  }
  .placeholder {
    width: 100%;
    height: 1px;
    background-color: #f8f9fa;
  }
  .mdeskgig{
    color:black;
    font-size:1.2em;
    text-align:center;
    margin:40px 0px;
  }
  
  .mdeskgig a{
    display: inline-block;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: black; /* Primary color */
    border: none;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size:0.6em;
  }

  
  .mdeskgig p{
    color:black;
    font-size:0.8em;
    text-align:center;
  }
  .mobileimage-wrapper {
    max-width: 600px;
    padding: 10px;
    background-color:rgba(244,244,244,0.5);
    border-radius:10px;
    transition:background-color 0.3s;
    text-align: center;
    
  }


  .mobileimage-wrapper img {
    width: 100%;
    height: auto;
    display: block;
  }
  .mobileimage-wrapper video {
    width: 100%;
    height: auto;
    margin:auto;
  }
  .mshows{
    padding:25px;
    font-size:1em;
    background-color:rgba(244, 244, 244, 0.9);
    color:black;
    text-align:center;
    width:80vw;
    margin:50px auto;
    margin-bottom:20px;
    box-shadow: 0 0 200px rgba(244, 244, 244, 0.9);
    border-radius:10px;
    animation: blur 1s;
  
  }
  @keyframes blur {
    0% {
      filter: blur(10px);
    }
    100% {
      filter: blur(0px);
    }
  }
  
  .mshows h2{
    font-size: 2em;
    font-family: "Telugu MN", serif;
    text-align: center;
    margin-bottom: 20px;
    color:black;
  }





