/* Ensure the container has relative positioning */
.transition-group {
  position: absolute;
}

/* Ensure the transitioning elements are absolutely positioned */
.fade-enter,
.fade-exit {
  text-align:center;
  width: 100%;
}

/* Fade transition */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 0ms;
}