/* Register.css */

/* Style the container */


/* Title */


/* Form */
form {
  display: flex;
  flex-direction: column;
  width: 800px;
  margin:auto;
  margin-top:150px;
}

/* Label */
label {
  font-size: 14px;
  margin-bottom: 5px;
  color: #555;
}

/* Input Fields */
input[type="text"],
input[type="email"],
input[type="password"] {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
}

input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

/* Submit Button */
button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Message */
p {
  text-align: center;
  margin-top: 10px;
  color: #555;
  font-size: 14px;
}
