.bio-container {
    width: 100vw;
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
    margin-top:50px;

  }
  
  
  .bio-title {
    font-size: 3rem;
    font-weight: bold;
    text-align: left;
    margin-bottom: 20px;
    color:black;
  }
  .headline-wrapper {
    position: relative;
    display: inline-block; /* Ensure the wrapper only takes up as much space as the subtitle */
  }
  
  .bioText {
    font-size: 1rem;
    margin: 0;
    color: white;
    font-size: 3em;
    text-shadow: 0px 0px 10px rgba(244, 244, 244, 1); /* Black shadow for better visibility */
    position: relative; /* Ensure the subtitle is positioned relative to the wrapper */
    z-index: 1; /* Ensure the subtitle is above the backborder */
    padding:40px;
  }
  
 

  
  .section-title {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 30px;
    color:black;
    text-align:left;
  }
  
  .bio-text {
    margin-bottom: 15px;
    text-align: left;
    color:black;
    font-size:1em;

  }
  .bioText{
    padding:100px;
    font-size:1.3em;
    background-color:rgba(244, 244, 244, 0.9);
    color:black;
    text-align:left;
    animation: blur 1s;
    width:80vw;
    margin:auto;
    margin-bottom:20px;
    box-shadow: 0 0 200px rgba(244, 244, 244, 0.9);
    border-radius:10px;
  }
  @keyframes blur {
    0% {
      filter: blur(10px);
    }
    100% {
      filter: blur(0px);
    }
  }
  
  .links-list,
  .members-list {
    list-style-type: none;
    padding: 0;
    text-align:left;
  }
  
  .links-list li,
  .members-list li {
    margin-bottom: 10px;
  }
  
  .links-list a {
    color: #007bff;
    text-decoration: none;
  }
  
  .links-list a:hover {
    text-decoration: underline;
  }
  .border{
    height:40px;
    background-color:black;
  }

.language-toggle {
  display: flex;
  justify-content: left;
  margin-bottom: 20px;
}

.flag {
  width: 30px;
  height: 20px;
  margin: 0 10px;
  cursor: pointer;
  opacity: 0.5;
}

.flag.active {
  opacity: 1;
  border: 2px solid #000;
}