/* Login.css */

/* Style the container */
.loginContainer {
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.loginContainer form{
  width:100%;
}

/* Title */
h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-family: Arial, sans-serif;
}

/* Form */
form {
  display: flex;
  flex-direction: column;
}
.loginCont{
  width:100%;
}
/* Label */
label {
  font-size: 14px;
  margin-bottom: 5px;
  color: #555;
}

/* Input Fields */
input[type="email"],
input[type="password"] {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
}

input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

/* Submit Button */
button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Message */
p {
  text-align: center;
  margin-top: 10px;
  color: #555;
  font-size: 14px;
}
