:root {
  --primary-color: #4CAF50;
  --secondary-color: #f1f1f1;
  --text-color: #333;
  --border-radius: 8px;
  --padding: 8px;
  --margin: 8px;
}


.project-management section {
  margin-bottom: 20px;
  opacity: 0;
  display:none;
  transition: all 0.5s ease-in-out;
}

.project-management .active-section {
  opacity: 1;
  display:contents;
}
.project-management {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--padding);
  margin-top:50px;
  
}

h2, h3, h4, h5, h6 {
  color: var(--primary-color);
}

.act-project, .project-item, .new-subtodo {
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: var(--padding);
  margin-bottom: var(--margin);
  display:flex;
  flex-wrap: wrap;
  gap: 16px; /* Adds space between items */

}

.new-subtodo{
  width:50%;
  margin:auto;
}


input[type="text"], input[type="date"], select, textarea {
  width:100%;
  padding: var(--padding);
  margin: var(--margin) 0;
  border: 1px solid #ccc;
  border-radius: var(--border-radius);
  box-sizing: border-box;
}

.dateInputShort{
  flex:1 1 calc(50% - var(--margin));
  width:calc(50% - var(--margin));
  margin:auto;
}

button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color 0.3s;
}



.projects-list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--margin);
  width:100%;
  text-align:center;
}
.projects-list table{
  width:100%;
  border-collapse: collapse; /* Ensures consistent cell spacing */

}

.projects-list p{
  font-weight: bold;
  width:fit-content;
}
.projectLine, .projectLineHead{
  margin:10px 0px;
  display:flex;
  justify-content:space-between;
}
.projectLine{
  border:1px solid black;
  border-radius:5px;
}

.projectLine td, th{
  flex: 1 1 calc(33.333% - var(--margin));
  padding:10px;
  display: flex;
  vertical-align: middle;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.subTodo td, th{
  flex: 1 1 calc(33.333% - var(--margin));
  padding:10px;
  display: flex;
  vertical-align: middle;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.project-item {
  flex: 1 1 calc(33.333% - var(--margin));
  display: flex;
  flex-direction: column;
}

.formLabel{
  font-weight: bold;

}
#project-members{
  width:100%;
  height:200px;
}

.sec1{
  flex: 1 1 30%;
  width:100%;
}
.projectDates{
  display:flex;
  gap:10px;
}
.sec2{
  width:100%;
  flex: 1 1 30%;

}
.sec3{
  width:100%;
  flex: 1 1 100%;

}
.sec3 textarea{
  width:100%;
  height:100px;
}
.aktivitetsoversikt-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.aktivitetsoversikt-table tr{
  display:flex;
  justify-content:space-between;
  border-radius:5px;
  margin:10px 0px;
}

.aktivitetsoversikt-table th,
.aktivitetsoversikt-table td {
  flex:1 1 calc(33.333% - var(--margin));
  border: 1px solid #ddd;
  text-align: center;
}
.aktivitetsoversikt-table td {
  background-color: white;
  }

.aktivitetsoversikt-table th {
  background-color: #f2f2f2;
}

.aktivitetsoversikt-table td button {
  flex:1 1 calc(50% - var(--margin));
  width:50%;
  margin: 5px;
}

.aktivitetsoversikt-table td button:last-child {
  margin-right: 0;
}

.button-group-subTodo{
  display:flex;
  justify-content:space-between;
}


