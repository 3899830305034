/* Shows.css */
.shows-container {
background-image: url("./assets/images/Void.png");
background-size: contain;
display: flex;
justify-content: center;
align-items: flex-start;
}
.shows-container div{
  flex: 1 1 33%;
}
.shows-container section{
  width:800px;
}
.calObj {
  border:1px dotted black;
  border-radius:20px;
  transition:all 1s;
  background-color:rgba(244,244,244,1);

}
.calObj:hover {
  transform:scale(1.1);
  background-color:rgba(244,244,244,1);

}


.calObj strong {
  display: block;
  font-size: 1.2em;
}

.calObj p {
  margin: 5px 0;
}

.sidebar {
  width: 200px;
  padding: 20px;
  background-color: #f8f9fa;
}

.city-list {
  list-style: none;
  padding: 0;
}

.city-list li {
  cursor: pointer;
  padding: 5px 0;
}

.city-list li.active {
  font-weight: bold;
  text-decoration: underline;
}

.filter-section {
  margin-left: 20px;
}

.filter-section select {
  padding: 5px;
  font-size: 1rem;
}

/* Remove dots from all lists */
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Past Events List */
.past-events-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.past-events-list .calObj {
  flex: 1 1 calc(50% - 20px); /* Two items per row with a gap of 20px */
  box-sizing: border-box;
}
/* Banner styles */
.banner {
  display: none;
  width: 50%;
  height: 200vh;
  background-image: url("./assets/images/Fontego_HvitHorizontal.png");
  background-position: center;
  background-size: 110%;
  background-position-y: 75px;
  margin: 0 20px;
}
.placeholder {
  width: 100%;
  height: 1px;
  background-color: #f8f9fa;
}
.deskgig{
  color:black;
  font-size:2em;
  text-align:center;
  margin:75px 0px;
  padding:30px;
}

.deskgig a{
  display: inline-block;
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: black; /* Primary color */
  border: none;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size:0.6em;
}
.deskgig a:hover {
  background-color: gold; /* Darken the primary color */
  color:black;
}

.deskgig p{
  color:black;
  font-size:0.8em;
  text-align:center;
}
.shows{
  padding:25px;
  font-size:1em;
  background-color:rgba(244, 244, 244, 0.9);
  color:black;
  text-align:center;
  width:80vw;
  margin:50px auto;
  margin-bottom:20px;
  box-shadow: 0 0 200px rgba(244, 244, 244, 0.9);
  border-radius:10px;
  animation: blur 1s;

}
@keyframes blur {
  0% {
    filter: blur(10px);
  }
  100% {
    filter: blur(0px);
  }
}

.shows h2{
  font-size: 3em;
  font-family: "Telugu MN", serif;
  text-align: center;
  margin-bottom: 20px;
  color:black;
}

/* Media query for desktop */
@media (min-width: 1024px) {
  .banner {
    display: block;
  }
}