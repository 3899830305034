
.front-page {
    display: flex;
    height:100vh;
    width:100vw;
    justify-content: space-between;
    background-image: url("./assets/images/Header.png");
    background-size: cover;
    
  }
  .floattext{
      position: absolute; /* Makes the text "stick" within the container */
      font-family: "Telugu MN", serif;
      font-size: 3rem;
      font-weight: bold;
      z-index: 10; /* Ensure it stays above the image */
      width:20%;
      height:auto;
      display: flex;
      justify-content: flex-end; /* Aligns the nav to the right */
  
  }
  #front-logo{
    position:fixed;
    bottom:150px;
    right:150px;
  }
  .logo {
    width: 300px; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
  }
  .logo {
    height: 50px;
  }
  
  .nav-list {
    display: flex;
    list-style: none;
  }
  
  .nav-list li {
    margin: 0 10px;
  }
  
  .nav-list a {
    color: white;
    text-decoration: none;
  }

  /* Media query for mobile devices */
@media (max-width: 768px) {
  body{
    margin:0;
    padding:0;
    background-color:black;
    height:300vh;
  }

  .front-page {
    background-image: url("./assets/images/Fontego_HvitHorizontal.png"); /* Replace with your mobile image path */
  }

  .floattext {
    font-size: 2rem; /* Adjust font size for mobile */
    width: 80%; /* Adjust width for mobile */
    justify-content: center; /* Center the text for mobile */
  }

  #front-logo {
    bottom: 50px; /* Adjust position for mobile */
    right: 50px; /* Adjust position for mobile */
  }

  .logo {
    width: 200px; /* Adjust width for mobile */
  }
}