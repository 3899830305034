/* App.css */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  
}

.background {
  position: fixed;
  top:-25%;
  left:-25%;
  width: 150%;
  height: 150%;
  background-image: url('./assets/images/Void.png'); /* Replace with your background image path */
  background-size: cover;
  background-position: center;
  transform-origin: center;
  z-index:-10;
}
.backgroundWhite {
  position: fixed;
  top:-20%;
  left:-25%;
  width: 150%;
  height: 150%;
  background-image: url('./assets/images/VoidWhite.png'); /* Replace with your background image path */
  background-size: cover;
  background-position: center;
  transform-origin: center;
  opacity:0.5;
  z-index:-9;
  animation:Blur 30s infinite linear;
}
@keyframes Blur{
  0%{
    opacity:0.7;
    top:-25%;
  }
  50%{
    opacity:0.3;
    top:-24.5%;
  }
  100%{
    opacity:0.7;
    top:-25%;
  }
}
.background-content{
  position: fixed;
  top:0;
  left:0;
  backdrop-filter: blur(4px); /* Apply blur effect */
  -webkit-backdrop-filter: blur(4px); /* For Safari support */

}
@keyframes fadeIn{
  0%{
    transform:rotate(0deg);
    opacity:0.08;
  }
  100%{
    transform:rotate(359deg);
    opacity:0.1;
  }
}

.content {
  position: relative;
  z-index: 10; /* Make sure content stays above the background */
  height: 200vh; /* Make the page scrollable */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: white;
  font-family: Arial, sans-serif;
}
