.content-editor {
  display:block;
  width: 800px;
  margin: auto;
  margin-top: 100px;
  padding:10px;
}

.content-editor button:hover {
  background-color: black;
}

.content-editor section {
  margin-bottom: 20px;
  opacity: 0;
  display:none;
  transition: all 0.5s ease-in-out;
}

.content-editor .active-section {
  opacity: 1;
  display:contents;
}

.content-editor .event-item,
.content-editor .promotion-item,
.content-editor .new-event,
.content-editor .new-promotion {
  margin: 40px 0;
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap onto multiple lines */
  gap: 1%; /* Space between items */
}
.new-promotion .promoDiv{
  
  flex:1 1 48%;
}
.input-with-button {
  display: flex;
  align-items: center;
}
.promoTitleDiv{
  width:100%;
  
}

.file-input {
  flex: 1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.file-button {
  flex: 0 0 150px; /* Adjust the width as needed */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
  margin:10px;
}
.editEventsList{
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  width:100%;
  align-content:center
}
.content-editor .calObj{
  width:80%;
  padding:10px;
  margin:auto;
  text-align:center;
}
.promoeditBtns{
  display:block;
  margin:auto;
  text-align: center !important;
}
.promoeditBtns button{
  display:block;
  width:100px;
  height:75px;
  margin:5px;
  padding: 0px 20px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.promotion-list{
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  border-radius:30px;
  padding:50px;
}
.promotion-item{
  width:100%;
  padding:20px;
  border-radius:50px;

}

.musicPromo{
  width:50%;
}

.inlineBtn{
  display:block;
  margin:auto;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border:red 1px solid !important;

}

.content-editor .new-event h4,
.content-editor .new-promotion h4 {
  flex: 1 1 100%; /* Make the h4 take up the full width */
  margin: 20px 0px !important; /* Add some space below the h4 */
}
.new-promotion input{
  margin:20px 0;
}
.new-promotion label{
  margin:20px 0;
}

.content-editor input,
.content-editor textarea {
  display: block;
  width: 350px;
  margin: 15px 1px;
  padding: 10px;
  border-radius: 4px;
}
.content-editor textarea {
  width:100%;
}

.content-editor button {
  padding: 5px 20px;
  background-color: black;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.content-editor button:hover {
  background-color: black;
}



#bio textarea{
  width:90%;
  margin:auto;
  margin-bottom:30px;
  height:300px;
  margin-top:50px;

}
#bioStore{
  margin:auto;
  text-align: center;
}
#bioStore button{
  margin:auto;
  background-color: green;
  color: white;
  border:1px solid black;
}
.musicPromo{
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  text-align: center;
  width:70%;
}
.promoDates{
  font-size:1.2em;
}
.selectedImage{
  display:flex;
  justify-content:center;
  align-items:center;
  min-height:100px;
  object-fit:cover;
  border-radius:50px;
  margin:auto;
}
.selectedImage > *{
  flex:1 1 10%;
  margin:10px;
}

.newpromo_dates{
  margin:auto;
}
.newPromoButton{
  margin:30px;
  background-color: white !important;
  color: black !important;
  border:1px solid black;

}
.link-item{
  flex:auto;
  margin:10px;
  width:40px;
  text-align:center;
}
.link-item svg{
  color:black;
  width:60px;
  height:60px;

}
.link-item svg:hover{
  color:green;
}
.submenu {
  display: flex;
  width: 100%;
}

.submenu button {
  flex: 1;
  padding: 10px;
  background-color: #f0f0f0;
  color:black;
  border: 1px solid #ccc;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
}

.submenu button.selected {
  background-color: #4CAF50;
  color: white;
}

.submenu button:not(.selected):hover {
  background-color: #ddd;
}

.active-section {
  display: block;
}

section:not(.active-section) {
  display: none;
}
.web_dash{
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  margin:auto;
  width:80%;
}
.promoContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex:1 1 50%;
  gap: 1rem;
  background-color: rgba(244, 244, 244, 0.2) !important;
}
.promoContainer h1{
margin:10px;
padding:0px;
}
.promoLinkDiv{
  flex:1 1 50%;
  border:1px solid black;
  padding:10px;
  margin:10px 0px;
  border-radius:5px;
  background-color:bisque;
}
.promoLinkDiv select{
  padding:10px;

}
.promoSubmitButtons{
  margin-top:20px;
  width:100%;
}
.promoSubmitButtons button{
  margin:10px;
  padding:10px;
  background-color:black;
  color:white;
  border:none;
  border-radius:5px;
  cursor:pointer;
}
.linkList{
  display:block;
  width:100%;
}
.linkList li{
  margin:5px 10px;
  overflow:hidden;
}
.linkListLi{
  display:flex;
  justify-content:space-between;
  text-align:center;
}
.links-wrapper{
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  width:100%;
  background-color:rgba(244,244,244,1);
  border-radius:50px;
  margin-top:10px;
}
.linkTitle{
  text-align: center;
}
