/* Header.css */
.header {
  position: fixed;
  font-family: "Telugu MN", serif; 
  top: 0;
  left: 0;
  width: 100%;
  height: 75px; /* Adjust height as needed */
  background-color: rgba(0, 0, 0, 0.9); /* Transparent black for overlay effect */
  color: white;
  display: flex;
  margin:0px;
  text-align:center;
  align-items: center;
  justify-content: space-between; /* Space between logo and nav */
  z-index: 1000; /* Ensure it's above other content */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional shadow */
}

.logo-link {
  display: flex;
  align-items: center;
}

.logo {
  height: 60px; /* Adjust height as needed */
  width: auto;
}

.menu-toggle {
  display: none;
  font-size: 2rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.nav {
  display: flex;
  flex-direction: column; /* Stack nav lists vertically */
  justify-content: flex-end; 
  width: 100%;
}

.nav-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: flex-end;

}

.nav-list li {
  margin-left: 20px; /* Space between the links */
  font-size:2em;
}

.nav-list li a {
  text-decoration: none;
  color: white; /* Example link color */
}

.nav-list li a:hover,
.nav-list li a.active {
  text-decoration: underline;
}

/* Additional styling for dashboard nav links */
.dashboard-nav {
  margin-top: 10px; /* Space between the main nav and dashboard nav */
  flex-direction: row; /* Align dashboard nav links horizontally */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }
  .header {
    text-align:center;
    flex-direction:row-reverse;
  }

  .nav {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .nav.open {
    display: flex;
  }

  .nav-list {
    flex-direction: column;
    width: 100%;
  }

  .nav-list li {
    margin: 10px 0;
  }

  .dashboard-nav {
    margin-top: 10px;
    flex-direction: column;
  }
}