

.musicContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
#music-container{
  display:flex;
  flex-wrap:wrap;
  width:800px;
  margin:auto;
  margin-top:100px;
  backdrop-filter: blur(4px); /* Apply blur effect */
  -webkit-backdrop-filter: blur(4px); /* For Safari support */
  border-radius: 30px; /* Optional: rounded corners */
  padding: 20px; /* Optional: padding inside the div */
  box-shadow: 0 0 30px 10px rgba(244, 244, 244, 1); /* Optional: subtle shadow */

}
.music{
  margin:auto;
  flex:1 1 50%;
}
.music0{
  order:1;

}
.music1{
  order:2;

}
.music2{
  order:3;
  
}
.music3{
  order:4;
  
}
.music4{
  order:5;
  
}
.music5{
  order:6;

  
}
.music6{
  order:7;

  
}
.music7{
  order:8;

  
}
.music8{
  order:9;

  
}
.music9{
  order:10;

  
}
  .title {
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin: 0;
  }

  .deskimage-wrapper {
    max-width: 600px;
    padding: 10px;
    background-color:rgba(244,244,244,0.5);
    border-radius:10px;
    transition:background-color 0.3s;
    text-align: center;
    
  }


  .deskimage-wrapper img {
    width: 100%;
    height: auto;
    display: block;
  }
  .deskimage-wrapper video {
    width: 100%;
    height: auto;
    margin:auto;
  }

  .subtitle-wrapper {
    position: relative;
    display: inline-block; /* Ensure the wrapper only takes up as much space as the subtitle */
    width:350px;
    margin:10px;
  }
  
  .subtitle {
    width:80%;
    margin: auto;
    color: black;
    font-size:1.3em;
    text-shadow: 0px 0px 10px rgba(244, 244, 244, 1); /* Black shadow for better visibility */
    position: relative; /* Ensure the subtitle is positioned relative to the wrapper */
    z-index: 1; /* Ensure the subtitle is above the backborder */
  }
  


  .headline-wrapper {
    position: relative;
    display: inline-block; /* Ensure the wrapper only takes up as much space as the subtitle */
  }
  
  .headtitle {
    font-size: 1.5rem;
    text-transform: uppercase;
    margin: 0;
    color: black;
    font-size: 3em;
    text-shadow: 0px 0px 10px rgba(244, 244, 244, 1); /* Black shadow for better visibility */
    position: relative; /* Ensure the subtitle is positioned relative to the wrapper */
    z-index: 1; /* Ensure the subtitle is above the backborder */
  }
  
  .backborderhd {
    position: absolute;
    height:75px;
    width:350px;
    background-color:rgba(244,244,244,0.3);
    border-radius:0%;
    box-shadow: 0 0 40px rgba(244, 244, 244, 0.6);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)rotate(0deg); /* Center the.subtitle-wrapper {
    position: relative;
    display: inline-block; /* Ensure the wrapper only takes up as much space as the subtitle */
  }
  .linkTitle {
    margin: 0;
    padding:20px 0px;
    color: black;
    font-size: 2em;
    color:rgba(0,0,0,0.8);
    text-shadow: 0px 0px 10px rgba(244, 244, 244, 1); /* Black shadow for better visibility */
    position: relative; /* Ensure the subtitle is positioned relative to the wrapper */
    z-index: 1; /* Ensure the subtitle is above the backborder */
    background-color:rgba(244,244,244,0.9);
    border-radius:10px;
  }
  .linkTitle h1{
    font-size:1.2em;
    margin:0 0 10px 0;
  }
  .linkTitle small{
    font-size:0.7em;
    margin:20px;
  }

  .social-icons {
    display: flex;
    justify-content: center;
  }

  .social-icons a {
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
    transition: color 0.3s ease;
    transform:scale(1.5);
    margin:20px;
    text-shadow: 2px 2px 4px rgba(0, 244, 0, 1); /* Black shadow for better visibility */
  }

  .promoDescription{
    margin:10px;
    padding:30px;
    border-radius:10px;
    text-align:center;
  }
  #music-container {
    perspective: 1000px;
  }
  


