#gallery {
    display: flex;
    flex-wrap: wrap;
    margin:0px;
    background-color:black;
    animation: blur 1s;
  }
  @keyframes blur {
    0% {
      filter: blur(10px);
    }
    100% {
      filter: blur(0px);
    }
  }
  .img{
    width:100%;
    height:100vh;
  }
  .img img {
    display: block;
    width: 100%;
    height: auto;
  }
  
  li, h2 {
    font-family: "Telugu MN", serif; 
  }
  
  .img:hover .overlay-text {
    opacity: 1;
  }